import axios from 'axios';
import { BASE_URI, BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';


const PREFIX = String(BASE_URI_DEVELOPMENT_CENTER);

/**
 * get user data
 *
 * @return  {Promise<import('axios').AxiosResponse<{
 *     success: boolean,
 *     logged_in: boolean,
 *     data: {
 *         id: number,
 *         name: string,
 *         short_name: string,
 *         login: string,
 *         email: string,
 *         roles: Array<string>,
 *         has_dc_access: boolean,
 *         avatar: string,
 *         created_at: string
 *     }
 * }>>}
 */
export const getUser = () => {
    const path = `${BASE_URI}/user`;

    return axios.get(path);
};

/**
 * logout user
 * @param {FormData} data logout formData
 * @return {Promise<import('axios').AxiosResponse>}
 */
export const logout = data => {
    const url = `${BASE_URI}/logout`;

    return axios({
        method: 'post',
        url,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};

/**
 * get users list with search
 * @param {string} search string
 * @return {Promise<import('axios').AxiosResponse>}
 */
export const searchUsers = search => axios.get(`${PREFIX}/users`, { params: { search } });
