import axios from 'axios';
import { Utils } from '@sbs/uikit-constructor';
import { BASE_URI } from '@/api/CONST.js';

/**
 * send message to support
 * @param {object} supportData support data
 * @param {string} supportData.message
 * @param {File} supportData.file
 * @returns Promise
 */
export const sendFeedback = (feedbackData, callback) => {
    const url = `${BASE_URI}/support`;
    const formData = new FormData();
    const { message, link, support_type_id: typeId, file } = feedbackData;

    formData.append('message', message);
    formData.append('link', link);
    formData.append('support_type_id', typeId);

    if (file) formData.append('file', file);

    return axios.post(url, formData, {
        onUploadProgress: progressEvent => {
            if (Utils.Helpers.toRawType(callback) === 'Function') {
                callback(progressEvent);
            }
        },
    });
};

export const getSupportFeedbackTypes = () => axios.get(`${BASE_URI}/support/types`);
