import store from '@/store/index.js';
import { userActionTypes, userGetterTypes } from '@/store/modules/user.js';
import { developmentCentersGetterTypes } from '@/admin/store/modules/developmentCenters/getters.js';
import { developmentCentersActionTypes } from '@/admin/store/modules/developmentCenters/actions.js';
import HttpStatusCodes from '@/utils/httpStatusCodes.js';
import { redirectXApiClient } from '@/utils/http.js';
import { delay } from '@/utils/utils.js';

const TIMEOUT_AFTER_REDIRECT = 10000;

/**
 * @var {import('vue-router').NavigationGuard}
 */
// eslint-disable-next-line complexity
export const authAndCenterIdGuard = async (to, _from, next) => {
    try {
        if (to.meta.test) return next();

        if (!store.getters[`user/${userGetterTypes.GET_USER}`].name) await store.dispatch(`user/${userActionTypes.GET_USER}`);

        const user = store.getters[`user/${userGetterTypes.GET_USER}`];
        const isDefaultUser = !user.has_dc_access;
        const toDevelopmentCenterId = String(to.params.developmentCenterId || '').trim();

        if (isDefaultUser) {
            if (Number(toDevelopmentCenterId) === 0) {
                store.dispatch('globals/setGlobalError', HttpStatusCodes.NOT_FOUND);

                return next();
            }

            // TODO: нужно всетаки уточнить дейсвительно ли это ок
            if (['AdminLongreadPreviewView', 'VersionPreviewView'].includes(to.name)) {
                const solutionId = String(to.params.solutionId || '').trim();
                const versionId = String(to.params.versionId || '').trim();
                const longreadId = String(to.params.longreadId || '').trim();

                redirectXApiClient({
                    projectId: solutionId,
                    courseId: versionId,
                    pageId: longreadId,
                });
                await delay(TIMEOUT_AFTER_REDIRECT);
                store.dispatch('globals/setGlobalError');

                return next();
            }

            store.dispatch('globals/setGlobalError', HttpStatusCodes.FORBIDDEN);

            return next();
        }

        if (!store.getters[`developmentCenters/${developmentCentersGetterTypes.GET_DEVELOPMENT_CENTERS}`]?.length) {
            await store.dispatch(`developmentCenters/${developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTERS}`);
        }

        if (!store.getters[`developmentCenters/${developmentCentersGetterTypes.GET_SELECTED_DEVELOPMENT_CENTER}`]) {
            await store.dispatch(`developmentCenters/${developmentCentersActionTypes.FETCH_SELECTED_DEVELOPMENT_CENTER}`);
        }

        const defaultDevelopmentCenterId = store.getters['developmentCenters/getDefaultDevelopmentCenterId'];

        if (toDevelopmentCenterId && !isNaN(toDevelopmentCenterId)) {
            next();
        } else {
            next({
                name: 'AdminProjectsList',
                params: {
                    developmentCenterId: defaultDevelopmentCenterId,
                },
            });
        }
    } catch (e) {
        console.error(e);
        store.dispatch('globals/setGlobalError');
        next();
    }
};

/**
 * удаляем лишний
 *
 * TODO: удалить когда бекенд уберет лишнее  #auth=plug
 */
export const urlGuard = (to, from, next) => {
    if (to.hash.includes('#auth')) {
        const fullPath = to.fullPath.replace(/#auth=[^#?]*/, '');

        next(fullPath);
    } else {
        next();
    }
};
