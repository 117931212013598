<template>
    <div class="UserHeaderNav d-flex align-items-center">
        <div class="text-right mr-1">
            <div class="typo-additional-book">
                {{ user.name }}
            </div>
            <div class="main-admin-header__user-email typo-subsecondary-demi clr-gray-deep">
                {{ user.email }}
            </div>
        </div>
        <SbsUserNavigation
            v-if="user"
            v-model="isUserMenuVisible"
            :avatarImageSrc="user.avatar">
            <div class="main-admin-header__user-name px-5 typo-subprimary-book">
                {{ userName }}
            </div>
            <div class="main-admin-header__user-divider mx-5 my-3 bg-gray-light"></div>
            <SbsLink
                class="px-5 mb-3"
                text="Связаться с поддержкой"
                colorTheme="black"
                hoverColor="primary"
                :underlined="false"
                @click="openSupportModal">
                <template #prepend>
                    <SbsIcon
                        class="mr-2"
                        iconName="circle-question" />
                </template>
            </SbsLink>
            <SbsLink
                class="px-5"
                text="Выйти из аккаунта"
                colorTheme="black"
                hoverColor="primary"
                :underlined="false"
                @click="openConfirmLogoutModal">
                <template #prepend>
                    <SbsIcon
                        class="mr-2"
                        iconName="sign-out" />
                </template>
            </SbsLink>
        </SbsUserNavigation>

        <LogoutModal v-model="isLogoutModalVisible" />
        <SupportModal v-model="isSupportModalOpen" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { userGetterTypes } from '@/store/modules/user.js';
import LogoutModal from '@/components/LogoutModal.vue';
import SupportModal from '@/components/SupportModal.vue';

const { mapGetters: mapUserGetters } = createNamespacedHelpers('user');

export default {
    name: 'UserHeaderNav',
    components: {
        SupportModal,
        LogoutModal,
    },
    data: () => ({
        isLogoutModalVisible: false,
        isUserMenuVisible: false,
        isSupportModalOpen: false,
    }),
    computed: {
        ...mapUserGetters({ user: userGetterTypes.GET_USER }),
        userName() {
            if (!this.user?.short_name && !this.user?.name?.trim()) return '';

            return this.user?.short_name ? this.user?.short_name : this.user?.name?.trim();
        },
    },
    methods: {
        openConfirmLogoutModal() {
            this.isLogoutModalVisible = true;
            this.isUserMenuVisible = false;
        },
        openSupportModal() {
            this.isSupportModalOpen = true;
            this.isUserMenuVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.main-admin-header__user-divider {
    height: 1px;
}

.main-admin-header__user-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.main-admin-header__user-email {
    margin-top: 2px;
}
</style>
