<template>
    <ErrorWrapper
        class="ErrorLayout"
        :code="globalErrorType"
        @click:navBtn="goHome" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { developmentCentersGetterTypes } from '@/admin/store/modules/developmentCenters/getters.js';
import { userGetterTypes } from '@/store/modules/user.js';
import ErrorWrapper from '@/components/UI/errors/ErrorWrapper.vue';

const {
    mapGetters: mapDevelopmentCentersGetters,
} = createNamespacedHelpers('developmentCenters');

export default {
    name: 'ErrorLayout',
    components: { ErrorWrapper },
    computed: {
        ...mapGetters({
            globalErrorType: 'globals/globalErrorType',
            user: `user/${userGetterTypes.GET_USER}`,
        }),
        ...mapDevelopmentCentersGetters({
            selectedDevelopmentCenter: developmentCentersGetterTypes.GET_SELECTED_DEVELOPMENT_CENTER,
        }),
    },
    methods: {
        ...mapActions({
            removeGlobalError: 'globals/removeGlobalError',
        }),
        async goHome() {
            if (this.user.has_dc_access) {
                // TODO возможно нужно кудадто в другое место
                await this.$router.push({
                    name: 'AdminProjectsList',
                    params: { developmentCenterId: this.selectedDevelopmentCenter?.id ?? this.$route.params?.developmentCenterId },
                });
                await this.removeGlobalError();
            } else {
                window.location.assign('https://sberuniversity.online/');
            }
        },
    },
};
</script>

<style lang="scss">
.ErrorLayout {
    min-height: 100vh;
    // min-height: calc(100vh * var(--zoom));
}
</style>
