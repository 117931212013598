<template>
    <SbsModal
        v-bind="logoutModalData"
        v-model="isLogoutModalVisible">
        <div class="confirm-logout-modal__content">
            <h2 class="text-center mb-4 typo-h3-bold">
                Вы уверены, что хотите<br>
                выйти из аккаунта?
            </h2>
            <div class="d-flex justify-content-center">
                <SbsButton
                    class="flex-shrink-0 mr-3 typo-primary-book"
                    text="Да, выйти"
                    :bordered="false"
                    backgroundTheme="primary"
                    hoverShadow="primary"
                    colorTheme="white"
                    size="large"
                    @click="logoutClick" />
                <SbsButton
                    class="flex-shrink-0 typo-primary-book"
                    text="Нет, остаться"
                    borderTheme="gray"
                    backgroundTheme="gray-ultra-light"
                    hoverShadow="gray"
                    size="large"
                    @click="isLogoutModalVisible = false" />
            </div>
        </div>
    </SbsModal>
</template>

<script>
import _get from 'lodash/get.js';
import { createNamespacedHelpers } from 'vuex';
import { userActionTypes } from '@/store/modules/user.js';
import { redirectToLogout } from '@/utils/http.js';

const { mapActions } = createNamespacedHelpers('user');

export default {
    name: 'LogoutModal',
    emits: ['update:modelValue'],
    computed: {
        isLogoutModalVisible: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        logoutModalData() {
            return {
                width: 469,
                minHeight: 216,
            };
        },
        logoutToken() {
            return _get(document.querySelector('[name="csrf-token"]'), 'content', '');
        },
    },
    methods: {
        ...mapActions({ logout: userActionTypes.LOGOUT }),
        async logoutClick() {
            await this.logout(this.logoutToken);

            redirectToLogout();
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
