import axios from 'axios';
import useSnackbar from '@/hooks/snackbars.js';
import { getErrorMsg } from '@/utils/helpers.js';

/**
 * @type {{ [key: string]: import('axios').CancelTokenSource }}
 */
const tokens = {};

/**
 * @param {string} key
 * @returns {import('axios').CancelTokenSource}
 */
export const createCancelHttp = key => {
    if (tokens[key]) {
        tokens[key].cancel(`cancel ${key}`);
    }

    tokens[key] = axios.CancelToken.source();

    return tokens[key];
};

/**
 * @param {any} err
 * @returns {boolean}
 */
export const isHttpCancel = err => axios.isCancel(err);

/**
 * @param err
 * @returns {err is import('axios').AxiosError}
 */
export const isAxiosError = err => axios.isAxiosError(err);

/**
 * @param {import('axios').AxiosResponse<{ success: boolean, data: any }>} resp
 * @returns {boolean}
 * @throws {import('axios').AxiosResponse<{ success: boolean, data: any }>}
 */
export const checkResponseSuccess = resp => {
    if (!resp.data.success) throw resp;

    return true;
};

const { createSnackbar } = useSnackbar();

export const showErrorSnackbar = err => {
    console.error(err);

    createSnackbar({
        type: 'error',
        message: getErrorMsg(err) || 'Ошибка. Попробуйте еще раз',
    });
};

export const redirectToAuth = () => {
    const authUrl = new URL('/login/authorize', process.env.VUE_APP_BACKEND_API_URL);

    authUrl.searchParams.append('backTo', window.location.href);
    location.assign(authUrl);
};

export const redirectToLogout = () => {
    if (process.env.NODE_ENV === 'production') {
        location.assign('https://sberuniversity.online/auth/logout');
    } else {
        location.reload();
    }
};

/**
 * @param {{
 *     projectId: string,
 *     courseId: string,
 *     pageId?: string
 * }} params
 */
export const redirectXApiClient = params => {
    const { projectId, courseId, pageId } = params;

    const xapiUrl = new URL(
        pageId
            ? `/client/xapi/solutions/${projectId}/versions/${courseId}/longreads/${pageId}`
            : `/client/xapi/solutions/${projectId}/versions/${courseId}`,
        process.env.VUE_APP_CLIENT_DOMAIN,
    );

    location.assign(xapiUrl);
};
