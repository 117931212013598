<template>
    <SbsFileList
        v-bind="fileListData"
        v-model="fileListModel"
        @removeFile="removeFileHandler"
        @dragenter="dragenterHandler"
        @dragleave="dragleaveHandler"
        @drop="dropHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @add="addHandler"
        @click="clickHandler"
        @error:fileExtension="errorFileExtensionHandler"
        @error:maxFileSize="errorMaxFileSizeHandler"
        @error:maxFilesLimit="errorMaxFilesLimitHandler">
        <template #preview="slotProps">
            <slot
                name="preview"
                :file="slotProps.file"
                :index="slotProps.index"></slot>
        </template>

        <template #upload>
            <slot name="upload"></slot>
        </template>
    </SbsFileList>
</template>

<script>
import { getFileData } from '@/utils/fileData.js';

const ErrorMessages = {
    FILE_TYPE: 'Неверный формат файла',
    FILE_SIZE: 'Слишком большой размер файла',
    FILES_LIMIT: 'Превышено максимальное количество файлов',
};

export default {
    name: 'FileUploader',
    emits: [
        'change',
        'removeFile',
        'dragenter',
        'dragleave',
        'drop',
        'focus',
        'blur',
        'add',
        'click',
        'error:fileExtension',
        'error:maxFileSize',
        'error:maxFilesLimit',
        'update:modelValue',
    ],
    data() {
        return {
            uploaderErrorMessages: [],
        };
    },
    computed: {
        fileListModel: {
            get() {
                return this.modelValue;
            },
            set(value) {
                const files = value.map(file => this.getFileData(file.content || file));

                this.$emit('change', files);
                this.$emit('update:modelValue', files);
            },
        },
        // eslint-disable-next-line max-lines-per-function
        fileListData() {
            return {
                ...this.$attrs,
                acceptFileExtensions: this.acceptFileExtensions,
                beforeChange: this.beforeChange,
                cancelIconTheme: this.cancelIconTheme,
                disabled: this.disabled,
                downloadable: this.downloadable,
                downloadIconTheme: this.downloadIconTheme,
                draggable: this.draggable,
                draggableListeners: this.draggableListeners,
                draggableOptions: this.draggableOptions,
                errorMessages: this.uploaderErrorMessages,
                fileBackgroundTheme: this.fileBackgroundTheme,
                fileHoverBackground: this.fileHoverBackground,
                fileIcon: this.fileIcon,
                fileIconTheme: this.fileIconTheme,
                fileInfoTheme: this.fileInfoTheme,
                fileNameTheme: this.fileNameTheme,
                filePreviewerClass: this.filePreviewerClass,
                fileUploaderBackgroundTheme: this.fileUploaderBackgroundTheme,
                fileUploaderBordered: this.fileUploaderBordered,
                fileUploaderBorderTheme: this.fileUploaderBorderTheme,
                fileUploaderClass: this.fileUploaderClass,
                fileUploaderColorTheme: this.fileUploaderColorTheme,
                fileUploaderDisabled: this.fileUploaderDisabled,
                fileUploaderDragAndDrop: this.fileUploaderDragAndDrop,
                fileUploaderHoverBackground: this.fileUploaderHoverBackground,
                fileUploaderHoverBorder: this.fileUploaderHoverBorder,
                fileUploaderHoverColor: this.fileUploaderHoverColor,
                fileUploaderLabelClass: this.fileUploaderLabelClass,
                loaderBorderRadius: this.loaderBorderRadius,
                loaderStrokeTheme: this.loaderStrokeTheme,
                loaderStrokeWidth: this.loaderStrokeWidth,
                maxFilesLimit: this.maxFilesLimit,
                maxFileSize: this.maxFileSize,
                multiple: this.multiple,
                showFileList: this.showFileList,
                showUploader: this.showUploader,
            };
        },
    },
    methods: {
        // eslint-disable-next-line complexity
        getFileData(file) {
            return getFileData(file, { downloadable: this.downloadable });
        },
        removeErrorMessages() {
            if (this.uploaderErrorMessages.length > 0) {
                this.uploaderErrorMessages = [];
            }
        },
        removeFileHandler() {
            this.$emit('removeFile');
        },
        dragenterHandler(e) {
            this.$emit('dragenter', e);
        },
        dragleaveHandler(e) {
            if (e?.target?.classList?.contains('sbs-file-uploader__label')) {
                this.$emit('dragleave', e);
            }
        },
        dropHandler(e) {
            this.$emit('drop', e);
        },
        focusHandler(e) {
            this.$emit('focus', e);
        },
        blurHandler(e) {
            this.$emit('blur', e);
        },
        addHandler(e) {
            this.$emit('add', e);
        },
        clickHandler(e) {
            this.removeErrorMessages();
            this.$emit('click', e);
        },
        errorFileExtensionHandler(e) {
            this.uploaderErrorMessages.push(ErrorMessages.FILE_TYPE);
            this.$emit('error:fileExtension', e);
        },
        errorMaxFileSizeHandler(e) {
            this.uploaderErrorMessages.push(ErrorMessages.FILE_SIZE);
            this.$emit('error:maxFileSize', e);
        },
        errorMaxFilesLimitHandler(e) {
            this.uploaderErrorMessages.push(ErrorMessages.FILES_LIMIT);
            this.$emit('error:maxFilesLimit', e);
        },
    },
    inheritAttrs: false,
    model: {
        event: 'change',
    },
    props: {
        /**
         * Допустимые типы файлов
         */
        acceptFileExtensions: {
            type: Array,
            default: () => [],
        },
        /**
         * Коллбэк, срабатываемый после выбора файлов в проводнике, но перед событием change
         */
        beforeChange: {
            type: Function,
            default: null,
        },
        /**
         * Цвет иконки отмены загрузки/скачивания
         */
        cancelIconTheme: {
            type: String,
            default: 'gray',
        },
        /**
         * Отключает любые действия с компонентом
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Включает возможность скачивания
         */
        downloadable: {
            type: Boolean,
            default: false,
        },
        /**
         * Цвет иконки загрузки/скачивания
         */
        downloadIconTheme: {
            type: String,
            default: 'black',
        },
        /**
         * Включает возможность менять файлы местами с помощью драг-н-дропа.
         */
        draggable: {
            type: Boolean,
            default: false,
        },
        /**
         * Объект со слушателями событий `SbsDraggable`
         */
        draggableListeners: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Объект с настройками `SbsDraggable`
         */
        draggableOptions: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Цвет фона под иконкой файла
         */
        fileBackgroundTheme: {
            type: String,
            default: 'gradient-pale-blue',
        },
        /**
         * Цвет фона под иконкой при наведении
         */
        fileHoverBackground: {
            type: String,
            default: 'gray-ultra-light',
        },
        /**
         * Иконка файла
         */
        fileIcon: {
            type: String,
            default: 'file',
        },
        /**
         * Цвет иконки файла
         */
        fileIconTheme: {
            type: String,
            default: 'primary',
        },
        /**
         * Цвет информации о файле
         */
        fileInfoTheme: {
            type: String,
            default: 'gray-deep',
        },
        /**
         * Цвет имени файла
         */
        fileNameTheme: {
            type: String,
            default: 'black',
        },
        /**
         * Класс для блока с загруженным файлом
         */
        filePreviewerClass: {
            type: String,
            default: '',
        },
        /**
         * Цвет фона для поля загрузки файла
         */
        fileUploaderBackgroundTheme: {
            type: String,
            default: '',
        },
        /**
         * Включает бордер для поля загрузки файла
         */
        fileUploaderBordered: {
            type: Boolean,
            default: false,
        },
        /**
         * Цвет бордера для поля загрузки файла
         */
        fileUploaderBorderTheme: {
            type: String,
            default: '',
        },
        /**
         * Класс для поля загрузки файла
         */
        fileUploaderClass: {
            type: String,
            default: '',
        },
        /**
         * Тема (цветовые решения) для цвета текста для поля загрузки файла
         */
        fileUploaderColorTheme: {
            type: String,
            default: '',
        },
        /**
         * Отключает поле загрузки файла
         */
        fileUploaderDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Включает возможность добавлять файлы перетаскиванием в поле загрузки файла
         */
        fileUploaderDragAndDrop: {
            type: Boolean,
            default: false,
        },
        /**
         * Тема (цветовые решения) для цвета фона для поля загрузки файла при наведении
         */
        fileUploaderHoverBackground: {
            type: String,
            default: '',
        },
        /**
         * Тема (цветовые решения) для цвета бордера для поля загрузки файла при наведении
         */
        fileUploaderHoverBorder: {
            type: String,
            default: '',
        },
        /**
         * Тема (цветовые решения) для цвета текста для поля загрузки файла при наведении
         */
        fileUploaderHoverColor: {
            type: String,
            default: '',
        },
        /**
         * Класс для лейбла для поля загрузки файла
         */
        fileUploaderLabelClass: {
            type: String,
            default: '',
        },
        /**
         * Радиус полосы загрузки
         */
        loaderBorderRadius: {
            type: Number,
            default: 10,
        },
        /**
         * Цвет полосы загрузки
         */
        loaderStrokeTheme: {
            type: String,
            default: 'primary',
        },
        /**
         * Толщина полосы загрузки
         */
        loaderStrokeWidth: {
            type: Number,
            default: 2,
        },
        /**
         * Допустимое количество одновременно добавленных файлов
         */
        maxFilesLimit: {
            type: Number,
            default: 10,
        },
        /**
         * Допустимый размер загружаемого файла в мегабайтах
         */
        maxFileSize: {
            type: Number,
            default: 70,
        },
        /**
         * Флаг загрузки нескольких файлов
         */
        multiple: {
            type: Boolean,
            default: false,
        },
        /**
         * Определяет видимость списка файлов
         */
        showFileList: {
            type: Boolean,
            default: true,
        },
        /**
         * Определяет видимость поля загрузки файла
         */
        showUploader: {
            type: Boolean,
            default: false,
        },
        /**
         * @model
         */
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
