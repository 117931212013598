import debounce from 'lodash/debounce.js';
import { compare, deepClone } from 'fast-json-patch';
import axios from 'axios';
import { Utils } from '@sbs/uikit-constructor';
import omit from 'lodash/omit.js';
import { get, merge } from 'lodash';
import { toRaw } from 'vue';
import { favoritesTab } from '@/fixtures/activityTypes.js';
import {
    addPageActivity,
    copyPageActivity,
    sortPageActivities,
    editPageActivity,
    removePageActivity,
    restorePageActivity, getPageActivity,
} from '@/api/activityApi.js';
import { getActivityTypesList, addActivitiTypeToBookmarks, removeActivityTypeFromBookmarks } from '@/api/activityTypeApi.js';
import { getPageById, updatePage } from '@/api/pageApi.js';
import { isActivityEmpty } from '@/utils/helpers.js';
import { COMPLETED_PROGRESS, DEBOUNCE_TIME } from '@/constants/index.js';
import { checkResponseSuccess, createCancelHttp, isHttpCancel, showErrorSnackbar as showError } from '@/utils/http.js';
import {
    addActivityAttachment, getAttachment,
    removeActivityAttachment as removeActivityAttachmentApi,
    restoreActivityAttachment,
} from '@/api/attachmentsApi.js';
import {
    getNewBlockInstance,
    getNewBlockInstancePatch,
    replaceItemInArrayById,
    getValueByPath,
    getUploadProgress,
    zipRecordsObjectDeep, pathToDotPath,
} from '@/utils/utils.js';
import {
    ACCEPT_AUDIO_TYPES,
    ACCEPT_COVER_TYPES,
    AUDIO_COVER_UPLOAD_KEY,
    AUDIO_FILE_UPLOAD_KEY,
    MAX_AUDIO_FILE_BYTES,
    MAX_COVER_FILE_BYTES,
} from '@/components/Blocks/BlockAudio/constants.js';
import useSnackbar from '@/hooks/snackbars.js';
import { validateFile } from '@/utils/validators.js';
import {
    ACCEPT_EVENT_COVER_TYPES,
    EVENT_COVER_UPLOAD_KEY,
    MAX_EVENT_COVER_FILE_BYTES,
} from '@/components/Blocks/BlockEvent/constants/index.js';
import {
    ACCEPT_VIDEO_COVER_TYPES, ACCEPT_VIDEO_EXTENSION, ACCEPT_VIDEO_SUBTITLES_EXTENSION,
    MAX_VIDEO_COVER_SIZE_BYTES,
    MAX_VIDEO_FILE_BYTES, MAX_VIDEO_SUBTITLES_SIZE_BYTES,
    VIDEO_COVER_FILE_UPLOAD_KEY,
    VIDEO_FILE_UPLOAD_KEY, VIDEO_SUBTITLES_FILE_UPLOAD_KEY,
} from '@/components/Blocks/BlockVideo/constants.js';
import activityQuestionActions from './activityQuestionActions.js';

const DEFAULT_BLOCK_UPLOAD_KEY = 'default';

const { createSnackbar } = useSnackbar();

const normalizeActivity = block => ({
    ...block,
    data: JSON.parse(block.data),
});

const rawActivity = block => ({
    ...block,
    data: JSON.stringify(block.data),
});

export default {
    namespaced: true,
    state: {
        activeBlockId: null,
        activeBlockElement: null,
        longreadStructure: null,
        initialStructure: null,
        longreadInitialName: '',
        initialPageActivities: [],
        pageActivities: [],
        pageActivitiesError: false,
        isLongreadUpdating: false,
        longreadClipboard: [],
        activityTypesList: [],
        deletedBlocksIds: [],
        loadingActivityTypesList: [],
        selectedActivities: [],
        editabledAnswerId: null,
        importing: { },
        currentQuestions: {},
        importProgresses: {},
        attachmentUploads: {},
        dragCreateBlock: null,
    },
    getters: {
        isPageActivitiesError: state => state.pageActivitiesError,
        isLongreadUpdating: state => state.isLongreadUpdating,
        longreadStructure: state => state.longreadStructure ?? {},
        initialStructure: state => state.initialStructure ?? {},
        isStructureChanged: state => Boolean(compare(state.initialStructure, state.longreadStructure).length),
        longreadId: (_state, getters) => getters.longreadStructure?.id ?? '',
        longreadName: (_state, getters) => getters.longreadStructure?.name ?? '',
        longreadInitialName: state => state.longreadInitialName,
        longreadAccessType: state => state.longreadStructure?.access_type ?? '',
        longreadUpdatedAt: (_state, getters) => getters.longreadStructure?.updated_at ?? '',
        longreadDisplayedItems: (_state, getters) => getters.longreadStructure?.activities ?? [],
        isSomeLongreadItemsHidden: (_state, getters) => getters.longreadDisplayedItems.some(item => !item.enabled),
        isEveryLongreadItemsHidden: (_state, getters) => getters.longreadDisplayedItems.every(item => !item.enabled),
        longreadSelectedItems: state => state.selectedActivities,
        isEducationalLongread: state => Boolean(state.longreadStructure?.is_educational),
        isLongreadItemsEmpty: (_state, getters) => !getters.longreadDisplayedItems.length,
        isMassActionsBarVisible: state => Boolean(state.selectedActivities.length),
        longreadClipboard: state => state.longreadClipboard,
        longreadContentList: state => {
            const favorites = {
                ...favoritesTab,
                activity_types: favoritesTab.activity_types(state.activityTypesList),
            };

            return [
                favorites,
                ...state.activityTypesList,
            ];
        },
        getActivityTypeLoading: state => id => Boolean(state.loadingActivityTypesList.find(item => item === id)),
        blockCutted: state => id => state.longreadClipboard.filter(item => item.type === 'cut').find(item => item.data.id === id),
        isActivitySelected: state => activity => state.selectedActivities.some(item => item.id === activity.id),
        getActivity: (_state, getters) => id => getters.longreadDisplayedItems.find(item => item.id === id),
        getEditabledAnswerId: state => state.editabledAnswerId,
        activeBlock: (state, getters) => getters.getActivity(state.activeBlockId),
        activeBlockId: state => state.activeBlockId,
        activeBlockElementId: state => state.activeBlockElement,
        getBlockCurrentQuestion: state => blockId => state.currentQuestions[blockId] || null,
        getBlockCurrentImport: state => blockId => state.importing[blockId] ?? false,
        getBlockCurrentImportProgress: state => blockId => state.importProgresses[blockId] || null,
        getBlockUploadingAttach: state => (blockId, key = DEFAULT_BLOCK_UPLOAD_KEY) => state.attachmentUploads[blockId]?.[key],
        getBlockUploadingError: state => (blockId, key = DEFAULT_BLOCK_UPLOAD_KEY) => state.attachmentUploads[blockId]?.errors?.[key],
        getQuestionMaxOrderAnswer: (state, getters) => (activityId, questionId, parentId) => {
            const currentActivity = getters.getActivity(activityId);
            const currentQuestion = currentActivity.questions.find(q => q.id === questionId);

            let maxOrder = currentQuestion.answers?.reduce((accOrder, answer) => Math.max(accOrder, answer.order ?? 0), 0);

            if (parentId) {
                maxOrder = currentQuestion
                    .answers
                    ?.find?.(answer => answer.id === parentId)
                    ?.children?.reduce((accOrder, childAnswer) => Math.max(accOrder, childAnswer.order ?? 0), 0);
            }

            return Math.max(maxOrder ?? -1, 0) + 1;
        },
        getQuestion: (state, getters) => (activityId, questionId) => {
            const currentActivity = getters.getActivity(activityId);

            return currentActivity?.questions?.find?.(q => q.id === questionId);
        },
        getQuestionAnswer: (state, getters) => (activityId, questionId, answerId, parentId) => {
            const currentQuestion = getters.getQuestion(activityId, questionId);
            const answers = parentId ? currentQuestion?.answers?.find?.(answer => answer.id === parentId)?.children : currentQuestion?.answers;

            return answers?.find(item => item.id === answerId);
        },
        isActivityDragCreationActive: state => Boolean(state.dragCreateBlock),
    },
    mutations: {
        /**
         * @param {any} state
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {number} payload.progress
         */
        changeBlockInportProgress(state, payload) {
            state.importProgresses = {
                ...state.importProgresses,
                [payload.blockId]: Math.min(payload.progress, COMPLETED_PROGRESS),
            };
        },
        /**
         * @param {any} state
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.questionId
         */
        changeCurrentQuestionId(state, payload) {
            state.currentQuestions = {
                ...state.currentQuestions,
                [payload.blockId]: payload.questionId,
            };
        },
        IMPORT_START(state, { blockId }) {
            state.importing = {
                ...state.importing,
                [blockId]: true,
            };
        },
        IMPORT_FINISH(state, { blockId }) {
            state.importing = {
                ...state.importing,
                [blockId]: false,
            };
        },
        SET_CONTENT_STRUCTURE(state, structure) {
            state.longreadStructure = structure ?? {};
        },
        SET_INITIAL_STRUCTURE(state, structure) {
            state.initialStructure = deepClone(structure) ?? {};
        },
        ADD_ACTIVITY_TO_STRUCTURE(state, data) {
            const { order = state.longreadStructure.activities.length } = data;

            state.longreadStructure.activities.splice(order, 0, data);
            state.longreadStructure.activities = state.longreadStructure.activities.map((item, index) => {
                item.order = index + 1;

                return item;
            });
        },
        SET_PAGE_ACTIVITIES(state, items) {
            state.longreadStructure.activities = items
                .map(item => normalizeActivity(item))
                .sort((a, b) => a.order - b.order);
        },
        CHANGE_ACTIVITY(state, block) {
            state.longreadStructure.activities = state.longreadStructure.activities.map(item => {
                if (item.id === block.id) {
                    return {
                        ...item,
                        ...block,
                    };
                }

                return item;
            });
        },
        REMOVE_ACTIVITY(state, deletedActivityId) {
            state.longreadStructure.activities = state.longreadStructure.activities.filter(activity => activity.id !== deletedActivityId);
        },
        RESTORE_ACTIVITY(state, restoredActivity) {
            const activities = deepClone(state.longreadStructure.activities);

            activities.push(restoredActivity);
            activities.sort((a, b) => a.order - b.order);
            state.longreadStructure.activities = activities;
        },
        SET_LONGREAD_UPDATING_STATE(state, isUpdating) {
            state.isLongreadUpdating = Boolean(isUpdating);
        },
        SET_LONGREAD_INITIAL_NAME(state, name) {
            state.longreadInitialName = name;
        },
        ADD_DATA_TO_LONGREAD_CLIPBOARD(state, data) {
            state.longreadClipboard.push(data);
        },
        CLEAR_LONGREAD_CLIPBOARD(state) {
            state.longreadClipboard = [];
        },
        SET_LONGREAD_CONTENT_LIST(state, list) {
            state.activityTypesList = list;
        },
        ADD_TYPE_ID_TO_LOADING_LIST(state, id) {
            state.loadingActivityTypesList = [...state.loadingActivityTypesList, id];
        },
        REMOVE_TYPE_ID_TO_LOADING_LIST(state, id) {
            state.loadingActivityTypesList = state.loadingActivityTypesList.filter(item => item !== id);
        },
        SET_SELECTED_ACTIVITIES(state, activities = []) {
            state.selectedActivities = activities;
        },
        REMOVE_SELECTED_ACTIVITIES(state, activitiesIds) {
            state.selectedActivities = state.selectedActivities.filter(activity => !activitiesIds.includes(activity.id));
        },
        SET_PAGE_ACTIVITY_ERROR(state, error) {
            state.pageActivitiesError = error;
        },
        SET_EDITABLED_ANSWER_ID(state, id) {
            state.editabledAnswerId = id;
        },
        SET_ACTIVE_BLOCK(state, { blockId, element }) {
            state.activeBlockId = blockId;
            state.activeBlockElement = element;
        },
        SET_UNACTIVE_BLOCK(state) {
            state.activeBlockId = null;
            state.activeBlockElement = null;
        },
        deactivateBlockElement(state) {
            state.activeBlockElement = null;
        },

        /**
         * @param {*} state
         * @param {object} payload
         * @param {string|number} payload.blockId,
         * @param {File} payload.file,
         * @param {string|number} [payload.key],
         * @param {object} [payload.name]
         */
        blockAttachUploadStart(state, payload) {
            const { blockId, file, key = DEFAULT_BLOCK_UPLOAD_KEY } = payload;

            const blockUploadingAttach = state.attachmentUploads[blockId] ?? {};

            blockUploadingAttach[key] = {
                id: Utils.Helpers.getRandomId(),
                file,
                progress: 0,
                name: file.name,
                size: file.size,
                type: file.type,
                ext: Utils.FileTypes.getFileExtension(file.name),
            };

            state.attachmentUploads[blockId] = blockUploadingAttach;
        },
        /**
         * @param {*} state
         * @param {object} payload
         * @param {*} payload.blockId,
         * @param {*} payload.key,
         * @param {*} payload.progress,
         */
        blockAttachUploadProgress(state, payload) {
            const { blockId, progress, key = DEFAULT_BLOCK_UPLOAD_KEY } = payload;

            state.attachmentUploads[blockId][key].progress = progress;
        },
        /**
         * @param {*} state
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.key
         */
        blockAttachUploadFinish(state, payload) {
            const { blockId, key = 'default' } = payload;

            state.attachmentUploads[blockId][key] = null;
        },

        /**
         * @param {*} state
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {Array<string|number>} payload.keys
         */
        blockAttachesUploadFinish(state, payload) {
            const { blockId, keys = 'default' } = payload;

            state.attachmentUploads[blockId] = {
                ...state.attachmentUploads[blockId],
                ...Object.fromEntries(keys.map(key => [key, null])),
            };
        },

        /**
         * @param {*} state
         * @param {object} payload,
         * @param {*} payload.blockId,
         * @param {*} payload.key,
         * @param {*} payload.error,
         */
        blockAttachUploadSetError(state, payload) {
            const { blockId, error, key = 'default' } = payload;

            state.attachmentUploads[blockId] = {
                ...state.attachmentUploads[blockId],
                errors: {
                    ...state.attachmentUploads[blockId]?.errors,
                    [key]: error,
                },
            };
        },
        setDragCreateBlock(state, block) {
            state.dragCreateBlock = block;
        },
    },
    actions: {
        async initContentStructure({ dispatch }, id) {
            try {
                const { data: pageData } = await getPageById(id);

                pageData.data.activities = pageData.data.activities.map(activity => normalizeActivity(activity));

                dispatch('setContentStructure', pageData.data);

                return null;
            } catch (error) {
                dispatch('globals/setGlobalerrorFromResponseData', error, { root: true });

                throw new Error(error);
            }
        },
        setContentStructure({ commit }, structure) {
            commit('SET_CONTENT_STRUCTURE', structure);
            commit('SET_INITIAL_STRUCTURE', structure);
        },
        async fetchPage({ dispatch, state }, { id, cancelToken }) {
            try {
                const { data: pageData } = await getPageById(id, {
                    cancelToken,
                });

                delete pageData.data.activities;

                dispatch('setContentStructure', {
                    ...state.longreadStructure,
                    ...pageData.data,
                });
            } catch (e) {
                if (!isHttpCancel(e)) {
                    console.error(e);
                }
            }
        },
        debouncedFetchPage: (() => {
            const debounceDispatchFetchPage = debounce(callback => callback(), 100);

            return ({ dispatch }, payload) => {
                const cancelToken = createCancelHttp('fetchPage').token;

                debounceDispatchFetchPage(() => {
                    dispatch('fetchPage', {
                        ...payload,
                        cancelToken,
                    });
                });
            };
        })(),
        async updateContentStructure({ dispatch }, structure) {
            const { id } = structure;

            try {
                const { data } = await updatePage(id, structure, {
                    cancelToken: createCancelHttp('updateContentStructure').token,
                });
                const result = data.data;

                result.activities = result.activities.map(activity => normalizeActivity(activity));

                dispatch('setContentStructure', result);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    throw new Error(error);
                }
            }
        },
        async addActivityToStructure({ commit, dispatch, getters }, blockData) {
            try {
                const { data } = await addPageActivity(getters.longreadId, blockData);
                const result = normalizeActivity(data.data);

                commit('ADD_ACTIVITY_TO_STRUCTURE', result);

                if (getters.longreadDisplayedItems.length <= 1) return;

                const order = getters.longreadDisplayedItems.map(activity => activity.id);

                sortPageActivities(getters.longreadId, { order });
                dispatch('debouncedFetchPage', { id: getters.longreadId });
            } catch (error) {
                throw new Error(error);
            }
        },
        async moveActivity({ commit, getters }, indexes) {
            const { fromIndex, toIndex } = indexes;
            const order = getters.longreadDisplayedItems.map(item => item.id);
            const movedId = order.splice(fromIndex, 1)[0];

            order.splice(toIndex, 0, movedId);

            try {
                const { data } = await sortPageActivities(getters.longreadId, { order });

                commit('SET_PAGE_ACTIVITIES', data.data);
            } catch (error) {
                throw new Error(error);
            }
        },
        async removeSingleActivity({ commit, dispatch, getters }, activityId) {
            await removePageActivity(getters.longreadId, activityId);

            commit('REMOVE_ACTIVITY', activityId);
            commit('REMOVE_SELECTED_ACTIVITIES', [activityId]);

            dispatch('debouncedFetchPage', { id: getters.longreadId });
        },
        async removeSelectedActivities({ commit, dispatch, getters }) {
            const structure = deepClone(getters.longreadStructure);
            const removedActivitiesIds = [];

            structure.activities = structure.activities.map(activity => {
                if (getters.isActivitySelected(activity)) {
                    // eslint-disable-next-line no-underscore-dangle
                    activity._destroy = true;
                    removedActivitiesIds.push(activity.id);
                }

                return rawActivity(activity);
            });

            try {
                await dispatch('updateContentStructure', structure);
                commit('REMOVE_SELECTED_ACTIVITIES', removedActivitiesIds);
            } catch (error) {
                throw new Error(error);
            }
        },
        async restoreActvity({ getters, dispatch, commit }, activityId) {
            const { data } = await restorePageActivity(getters.longreadId, activityId);

            const result = normalizeActivity(data);

            commit('RESTORE_ACTIVITY', result);

            dispatch('debouncedFetchPage', { id: getters.longreadId });
        },
        setLongreadUpdatingState({ commit }, isUpdating) {
            commit('SET_LONGREAD_UPDATING_STATE', isUpdating);
        },
        setLongreadInitialName({ commit }, name) {
            commit('SET_LONGREAD_INITIAL_NAME', name);
        },
        addDataToLongreadClipboard({ commit }, data) {
            commit('ADD_DATA_TO_LONGREAD_CLIPBOARD', data);
        },
        clearLongreadClipboard({ commit }) {
            commit('CLEAR_LONGREAD_CLIPBOARD');
        },
        async pasteActivities({ commit, getters, dispatch }, moveIndex) {
            try {
                const { type } = getters.longreadClipboard[0];

                if (type === 'copy') {
                    await dispatch('pasteCopiedActivities', moveIndex);
                }

                if (type === 'cut') {
                    await dispatch('pasteCuttedActivities', moveIndex);
                }

                dispatch('debouncedFetchPage', { id: getters.longreadId });
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                commit('CLEAR_LONGREAD_CLIPBOARD');
                commit('SET_SELECTED_ACTIVITIES');
            }
        },
        async pasteCopiedActivities({ getters, dispatch, commit }, moveIndex) {
            const pageId = getters.longreadId;
            const modifiedClipboard = getters.longreadClipboard.map(async activity => {
                const activityId = activity.data.id;
                const { data } = await copyPageActivity(pageId, activityId);

                return data;
            });

            let resultActivities = [...getters.longreadDisplayedItems];

            resultActivities.splice(moveIndex, 0, ...await Promise.all(modifiedClipboard));

            resultActivities = resultActivities.map((item, index) => rawActivity({
                ...item,
                order: index + 1,
            }));

            const order = resultActivities.map(activity => activity.id);

            const { data } = await sortPageActivities(getters.longreadId, { order });

            commit('SET_PAGE_ACTIVITIES', data.data);

            dispatch('debouncedFetchPage', { id: getters.longreadId });
        },
        async pasteCuttedActivities({ getters, dispatch, commit }, moveIndex) {
            let pastedBlocks = getters.longreadClipboard.map(item => item.data);

            pastedBlocks = pastedBlocks.map(item => item.id);

            const pastedBlocksSet = new Set(pastedBlocks);
            let order = getters.longreadDisplayedItems.map(item => item.id);

            order = order.filter(e => !pastedBlocksSet.has(e));
            order.splice(moveIndex, 0, ...pastedBlocks);

            const { data } = await sortPageActivities(getters.longreadId, { order });

            commit('SET_PAGE_ACTIVITIES', data.data);

            dispatch('debouncedFetchPage', { id: getters.longreadId });
        },
        async getLongreadContentList({ commit }, pageId) {
            const { data } = await getActivityTypesList(pageId);
            const list = data.data;

            commit('SET_LONGREAD_CONTENT_LIST', list);
        },
        async addFavoriteItem({ dispatch, commit }, { item, pageId }) {
            try {
                commit('ADD_TYPE_ID_TO_LOADING_LIST', item.id);

                await addActivitiTypeToBookmarks(item.id);
                dispatch('getLongreadContentList', pageId);
            } catch (error) {
                throw new Error(error);
            } finally {
                commit('REMOVE_TYPE_ID_TO_LOADING_LIST', item.id);
            }
        },
        async deleteFavoriteItem({ dispatch, commit }, { item, pageId }) {

            try {
                commit('ADD_TYPE_ID_TO_LOADING_LIST', item.id);

                await removeActivityTypeFromBookmarks(item.id);
                dispatch('getLongreadContentList', pageId);

            } catch (error) {
                throw new Error(error);
            } finally {
                commit('REMOVE_TYPE_ID_TO_LOADING_LIST', item.id);
            }
        },
        async changeBlock({ commit, dispatch }, block) {
            await commit('CHANGE_ACTIVITY', block);

            dispatch('debouncedActivitySave', {
                blockId: block.id,
                cancelToken: createCancelHttp('debouncedActivitySave').token,
            });
        },
        async changeBlockImmediate({ commit, dispatch }, block) {
            await commit('CHANGE_ACTIVITY', block);
            await dispatch('activitySave', {
                blockId: block.id,
            });
        },
        updateInitialStructure({ state, commit }) {
            // обноляем initialStructure, так как мы сохранили на беке,
            // иначче будет не правильно работаьь isStructureChanged
            // TODO: нужно проверить что везде после обновления/создания/сохранения структры,
            //  также меняется initialStructure, и вообще нужно
            //  провериьть актуальность initialStructure и isStructureChanged
            commit('SET_INITIAL_STRUCTURE', state.longreadStructure);
        },

        debouncedActivitySave: debounce(({ dispatch }, payload) => {
            dispatch('activitySave', payload);
        }, DEBOUNCE_TIME),

        /**
         * @param {import('vuex').ActionContext} context
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {import('axios').CancelToken} [payload.cancelToken]
         * @returns {Promise<void>}
         */
        async activitySave({ getters, dispatch }, payload) {
            const { blockId, cancelToken } = payload;

            const block = getters.getActivity(blockId);

            if (!block) return;

            await dispatch('saveActivityData', {
                block,
                cancelToken,
            });
        },

        /**
         * @param {import('vuex').ActionContext} context
         * @param {object} payload
         * @param {object} payload.block
         * @param {import('axios').CancelToken} [payload.cancelToken]
         * @returns {Promise<void>}
         */
        async saveActivityData({ commit, getters, dispatch }, {
            block,
            cancelToken,
        }) {
            try {
                const blockWithoutQuestion = omit(block, ['questions']);
                const blockDataForSave = rawActivity(blockWithoutQuestion);

                const { data: { data: savedBlockResponse } } = await editPageActivity(
                    getters.longreadId,
                    block.id,
                    blockDataForSave,
                    cancelToken,
                );
                const savedBlockWithoutQuestions = omit(savedBlockResponse, ['questions']);
                const savedBlock = normalizeActivity(savedBlockWithoutQuestions);

                commit('CHANGE_ACTIVITY', savedBlock); // после сохранения на беке, нужно пересохранить в сторе
                dispatch('updateInitialStructure');
                dispatch('debouncedFetchPage', { id: getters.longreadId });
            } catch (e) {
                if (isHttpCancel(e)) {
                    dispatch('updateInitialStructure');

                    return;
                }

                console.error('saving block error', e);
                throw e;
            }
        },

        toggleSelectedActivity({ commit, getters }, activity) {
            let selectedActivities = [...getters.longreadSelectedItems];

            if (getters.isActivitySelected(activity)) {
                selectedActivities = selectedActivities.filter(item => item.id !== activity.id);
            } else {
                selectedActivities.push(activity);
            }

            commit('SET_SELECTED_ACTIVITIES', selectedActivities);
        },
        resetSelectedActivities({ commit }) {
            commit('SET_SELECTED_ACTIVITIES');
        },
        toggleAllActivitiesSelection({ commit, getters }) {
            const activities = getters.longreadDisplayedItems.length === getters.longreadSelectedItems.length
                ? []
                : getters.longreadDisplayedItems;

            commit('SET_SELECTED_ACTIVITIES', activities);
        },
        async toggleSelectedBlocksVisibility({ dispatch, getters }, isVisible) {
            const newActivities = getters.longreadDisplayedItems.map(activity => {
                const shouldNotChangeVisibility = !getters.isActivitySelected(activity) ||
                    Boolean(activity.enabled) === isVisible ||
                    isActivityEmpty(activity);

                if (shouldNotChangeVisibility) {
                    return rawActivity(activity);
                }

                return rawActivity({
                    ...activity,
                    enabled: isVisible,
                });
            });
            const newStructure = {
                ...getters.longreadStructure,
                activities: newActivities,
            };

            await dispatch('updateContentStructure', newStructure);
        },
        checkActivitiesValid({ commit }, isValidItems) {
            commit('SET_PAGE_ACTIVITY_ERROR', !isValidItems);
        },
        setEditabledAnswerId({ commit }, id) {
            commit('SET_EDITABLED_ANSWER_ID', id);
        },
        /**
         * @param ctx
         * @param {string} blockId
         * @param {string} element
         */
        activateBlock({ commit }, { blockId, element = null }) {
            commit('SET_ACTIVE_BLOCK', {
                blockId,
                element,
            });
        },
        deactivateBlock({ commit }) {
            commit('SET_UNACTIVE_BLOCK');
        },

        /**
        * @param {import('vuex').ActionContext} ctx
        * @param {object} payload
        * @param {string|number} payload.longreadId
        * @param {string|number} payload.blockId
        * @returns {Promise<*>}
        */
        async refetchActivity({ commit }, payload) {
            try {
                const { longreadId, blockId } = payload;
                const resp = await getPageActivity(longreadId, blockId);
                const block = normalizeActivity(resp.data.data);

                commit('CHANGE_ACTIVITY', block);
            } catch (e) {
                console.error('refetchActivity', e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.attachId
         * @returns {Promise<*>}
         */
        async removeActivityAttachment({ getters, commit }, payload) {
            const { blockId, attachId } = payload;

            try {
                if (!attachId) return;

                await removeActivityAttachmentApi(blockId, attachId);

                const block = getters.getActivity(blockId);
                const newBlock = getNewBlockInstance(block, block?.attachments?.filter(attach => attach.id !== attachId), '/attachments');

                commit('CHANGE_ACTIVITY', newBlock);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @param {string|number} [payload.key]
         * @param {boolean} [payload.clearOnFinish]
         * @param {Function} [payload.success]
         * @param {Function} [payload.error]
         * @returns {Promise<*>}
         */
        async addActivityAttachment({ commit, getters }, payload) {
            const { blockId, key, file, clearOnFinish = true, success, error } = payload;

            try {

                commit('blockAttachUploadStart', {
                    blockId,
                    key,
                    file,
                });

                const resp = await addActivityAttachment(blockId, {
                    file,
                    name: file.name,
                }, progressEvent => {
                    const progress = getUploadProgress(progressEvent);

                    commit('blockAttachUploadProgress', {
                        blockId,
                        key,
                        progress,
                    });
                });

                checkResponseSuccess(resp);

                const block = getters.getActivity(blockId);
                const newAttach = resp.data.data;
                const newBlock = getNewBlockInstance(block, [...block.attachments, newAttach], '/attachments');

                commit('CHANGE_ACTIVITY', newBlock);

                await success?.(newAttach);

                return newAttach;
            } catch (e) {
                console.error(e);
                error?.(e);
                throw e;
            } finally {
                if (clearOnFinish) {
                    commit('blockAttachUploadFinish', {
                        blockId,
                        key,
                    });
                }
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.attachId
         * @returns {Promise<*>}
         */
        refetchActivityAttach: async ({ commit, getters }, payload) => {
            const { blockId, attachId } = payload;

            let block = getters.getActivity(blockId);
            let attach = block?.attachments?.find?.(attachItem => attachItem.id === attachId);

            if (!block || !attach) return;

            const attachResp = await getAttachment({
                activityId: blockId,
                attachmentId: attachId,
            });

            block = getters.getActivity(blockId);
            attach = block?.attachments?.find?.(attachItem => attachItem.id === attachId);

            const newAttach = attachResp.data.data;

            if (block && attach) {
                const newBlock = getNewBlockInstance(block, replaceItemInArrayById(newAttach, block.attachments), '/attachments');

                commit('CHANGE_ACTIVITY', newBlock);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {string|number} payload
         * @param {string|number} payload.blockId
         * @returns {Promise<*>}
         */
        refetchActivityAttachments: async ({ commit, getters, dispatch }, payload) => {
            try {
                const { blockId } = payload;
                const { longreadId } = getters;

                if (!longreadId || !blockId) return;

                const resp = await getPageActivity(longreadId, blockId);

                const block = getters.getActivity(blockId);
                const { attachments } = resp.data.data;

                if (block && attachments) {
                    const newBlock = getNewBlockInstance(block, attachments, '/attachments');

                    commit('CHANGE_ACTIVITY', newBlock);
                    dispatch('updateInitialStructure');
                }
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.attachId
         * @returns {Promise<*>}
         */
        async restoreActivityAttach({ commit, getters }, payload) {
            const { blockId, attachId } = payload;

            try {
                const resp = await restoreActivityAttachment(blockId, attachId);

                checkResponseSuccess(resp);

                const block = getters.getActivity(blockId);
                const newAttach = resp.data.data;
                const newBlock = getNewBlockInstance(block, [...block.attachments, newAttach], '/attachments');

                commit('CHANGE_ACTIVITY', newBlock);

                return newAttach;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @returns {Promise<void>}
         */
        async audioBlockAddAudioFile({ dispatch, getters }, payload) {
            const { blockId, file } = payload;

            try {
                validateFile(file, {
                    maxSize: MAX_AUDIO_FILE_BYTES,
                    types: ACCEPT_AUDIO_TYPES,
                });

                const prevAudioId = getters.getActivity(blockId)?.data?.content?.audioId;
                const attach = await dispatch('addActivityAttachment', {
                    blockId,
                    file,
                    key: AUDIO_FILE_UPLOAD_KEY,
                });

                const block = getters.getActivity(blockId);
                const newBlock = getNewBlockInstancePatch(block, [
                    ['/data/content/audioId', attach.id],
                    ...(block?.data?.content?.title ? [] : [['/data/content/title', Utils.FileTypes.getFileName(file.name)]]),
                ]);

                await dispatch('changeBlock', newBlock);

                if (prevAudioId) {
                    try {
                        await dispatch('removeActivityAttachment', {
                            blockId,
                            attachId: prevAudioId,
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }

            } catch (e) {
                showError(e);
                throw e;
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @returns {Promise<void>}
         */
        async audioBlockAddCoverFile({ dispatch, getters }, payload) {
            const { blockId, file } = payload;

            try {
                validateFile(file, {
                    maxSize: MAX_COVER_FILE_BYTES,
                    types: ACCEPT_COVER_TYPES,
                });

                const prevCoverId = getters.getActivity(blockId)?.data?.content?.coverId;

                const attach = await dispatch('addActivityAttachment', {
                    blockId,
                    file,
                    key: AUDIO_COVER_UPLOAD_KEY,
                });

                const block = getters.getActivity(blockId);
                const newBlock = getNewBlockInstance(block, attach.id, '/data/content/coverId');

                await dispatch('changeBlock', newBlock);

                if (prevCoverId) {
                    try {
                        await dispatch('removeActivityAttachment', {
                            blockId,
                            attachId: prevCoverId,
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
            } catch (e) {
                showError(e);
                throw e;
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @returns {Promise<void>}
         */
        async audioBlockDeleteCoverFile({ dispatch, getters }, payload) {
            const { blockId } = payload;

            try {
                const block = getters.getActivity(blockId);
                const coverId = block?.data?.content?.coverId;

                if (coverId) {
                    await dispatch('removeActivityAttachment', {
                        blockId,
                        attachId: coverId,
                    });

                    const newBlock = getNewBlockInstance(block, null, '/data/content/coverId');

                    await dispatch('changeBlock', newBlock);
                }
            } catch (e) {
                showError(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @returns {Promise<void>}
         */
        async audioBlockDeleteAudioFile({ dispatch, getters }, payload) {
            const { blockId } = payload;

            try {
                const block = getters.getActivity(blockId);
                const oldBlock = block;
                const audioId = block?.data?.content?.audioId;

                if (audioId) {
                    await dispatch('removeActivityAttachment', {
                        blockId,
                        attachId: audioId,
                    });

                    const newBlock = getNewBlockInstancePatch(block, [
                        ['/data/content/audioId', null],
                        ['/data/content/duration', 0],
                        ['/data/content/options/episodes', false],
                        ['/data/content/options/transcription', false],
                        ['/data/episodes', []],
                        ['/data/transcription', ''],
                    ]);

                    await dispatch('changeBlock', newBlock);
                    dispatch('audioBlockShowRestoreAudio', {
                        blockId,
                        audioId,
                        oldBlock,
                    });
                }
            } catch (e) {
                showError(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.audioId
         * @param {string|number} payload.blockData
         * @returns {Promise<*>}
         */
        audioBlockShowRestoreAudio({ dispatch }, payload) {
            const { blockId, audioId, oldBlock } = payload;

            createSnackbar({
                type: 'timer',
                message: 'Аудио удалено',
                actionButton: {
                    iconName: 'undo',
                    text: 'Отмена',
                    on: {
                        click: () => {
                            dispatch('audioBlockRestoreAudio', {
                                blockId,
                                audioId,
                                blockData: oldBlock,
                            });
                        },
                    },
                },
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.audioId
         * @param {string|number} payload.blockData
         * @returns {Promise<*>}
         */
        async audioBlockRestoreAudio({ dispatch }, payload) {
            const { blockId, audioId, blockData } = payload;

            try {
                await dispatch('restoreActivityAttach', {
                    blockId,
                    attachId: audioId,
                });
                await dispatch('changeBlock', blockData);
            } catch (e) {
                showError(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @returns {Promise<void>}
         */
        async eventBlockAddCoverFile({ dispatch }, payload) {
            const { blockId, file } = payload;

            await dispatch('uploadActivityAttach', {
                blockId,
                file,
                uploadKey: EVENT_COVER_UPLOAD_KEY,
                maxSize: MAX_EVENT_COVER_FILE_BYTES,
                types: ACCEPT_EVENT_COVER_TYPES,
                pathAttach: '/data/content/cover_file/attachment_id',
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @param {string} payload.uploadKey
         * @param {number} [payload.maxSize]
         * @param {number} [payload.minSize]
         * @param {string} [payload.types]
         * @param {string[]} [payload.extensions]
         * @param {string} payload.pathAttach
         * @returns {Promise<void>}
         */
        async uploadActivityAttach({ dispatch, commit, getters }, payload) {
            const { blockId, file, minSize, maxSize, types, extensions, pathAttach, uploadKey } = payload;

            try {
                commit('blockAttachUploadSetError', {
                    blockId,
                    key: uploadKey,
                    error: null,
                });

                validateFile(file, {
                    maxSize,
                    minSize,
                    types,
                    extensions,
                });

                const attach = await dispatch('addActivityAttachment', {
                    blockId,
                    file,
                    key: uploadKey,
                });

                const oldBlock = getters.getActivity(blockId);
                const dontPath = pathToDotPath(pathAttach);
                const prevCoverId = get(oldBlock, dontPath);
                const changes = zipRecordsObjectDeep({ [dontPath]: attach.id });
                const newBlock = merge({}, toRaw(oldBlock), changes);

                try {
                    await dispatch('changeBlockImmediate', newBlock);
                    dispatch('removePreviousAttach', {
                        blockId,
                        attachId: prevCoverId,
                    });
                } catch (e) {
                    // пробуем восстановить блок
                    await Promise.allSettled([dispatch('changeBlockImmediate', oldBlock)]);
                    throw e;
                }
            } catch (e) {
                showError(e);

                commit('blockAttachUploadSetError', {
                    blockId,
                    key: uploadKey,
                    error: e,
                });

                throw e;
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string} payload.uploadKey
         * @param {File} payload.file
         * @param {string|number} [payload.prevAttachId]
         * @param {Object} [payload.validation]
         * @param {Function} [payload.success]
         * @param {Function} [payload.error]
         * @returns {Promise<void>}
         */
        async uploadActivityAttachBase({ dispatch, commit }, payload) {
            const {
                blockId,
                prevAttachId,
                file,
                uploadKey,
                validation = {},
                success,
                error,
            } = payload;

            try {

                commit('blockAttachUploadSetError', {
                    blockId,
                    key: uploadKey,
                    error: null,
                });

                validateFile(file, validation);

                await dispatch('addActivityAttachment', {
                    blockId,
                    file,
                    key: uploadKey,
                    success,
                    error,
                });

                dispatch('removePreviousAttach', {
                    blockId,
                    attachId: prevAttachId,
                });
            } catch (e) {
                showError(e);

                commit('blockAttachUploadSetError', {
                    blockId,
                    key: uploadKey,
                    error: e,
                });
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.attachId
         * @returns {Promise<void>}
         */
        async removePreviousAttach({ dispatch }, { blockId, attachId }) {
            try {
                await dispatch('removeActivityAttachment', {
                    blockId,
                    attachId,
                });
            } catch (e) {
                console.error('[removePreviousAttach]', e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @returns {Promise<void>}
         */
        async eventBlockDeleteCoverFile({ dispatch }, payload) {
            const { blockId } = payload;

            await dispatch('removeActivityAttachmentByPath', {
                blockId,
                pathAttach: '/data/content/cover_file/attachment_id',
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string} payload.pathAttach
         * @returns {Promise<void>}
         */
        async removeActivityAttachmentByPath({ dispatch, getters }, payload) {
            const { blockId, pathAttach } = payload;

            try {
                const block = getters.getActivity(blockId);
                const coverId = getValueByPath(block, pathAttach);

                if (coverId) {
                    await dispatch('removeActivityAttachment', {
                        blockId,
                        attachId: coverId,
                    });

                    const newBlock = getNewBlockInstance(block, null, pathAttach);

                    await dispatch('changeBlockImmediate', newBlock);
                }
            } catch (e) {
                showError(e);
            }
        },
        updateActivityDragCreationActive({ commit }, isActive) {
            commit('setIsActivityDragCreationActive', isActive);
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @param {boolean} payload.activateBlock
         * @returns {Promise<void>}
         */
        async videoBlockAddVideoFile({ dispatch }, payload) {
            const { blockId, file, activateBlock = false } = payload;

            await dispatch('uploadActivityAttach', {
                blockId,
                file,
                uploadKey: VIDEO_FILE_UPLOAD_KEY,
                maxSize: MAX_VIDEO_FILE_BYTES,
                extensions: ACCEPT_VIDEO_EXTENSION,
                pathAttach: '/data/content/videoFile/id',
            });

            if (activateBlock) {
                dispatch('activateBlock', {
                    blockId,
                });
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @returns {Promise<void>}
         */
        async videoBlockAddCoverFile({ dispatch }, payload) {
            const { blockId, file } = payload;

            await dispatch('uploadActivityAttach', {
                blockId,
                file,
                uploadKey: VIDEO_COVER_FILE_UPLOAD_KEY,
                maxSize: MAX_VIDEO_COVER_SIZE_BYTES,
                types: ACCEPT_VIDEO_COVER_TYPES,
                pathAttach: '/data/content/coverFile/id',
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {File} payload.file
         * @returns {Promise<void>}
         */
        async videoBlockAddSubtitlesFile({ dispatch }, payload) {
            const { blockId, file } = payload;

            await dispatch('uploadActivityAttach', {
                blockId,
                file,
                uploadKey: VIDEO_SUBTITLES_FILE_UPLOAD_KEY,
                maxSize: MAX_VIDEO_SUBTITLES_SIZE_BYTES,
                extension: ACCEPT_VIDEO_SUBTITLES_EXTENSION,
                pathAttach: '/data/content/subtitlesFile/id',
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @returns {Promise<void>}
         */
        async videoBlockDeleteVideoFile({ dispatch, getters }, payload) {
            const { blockId } = payload;

            try {
                const videoIdPath = '/data/content/videoFile/id';
                const oldBlock = getters.getActivity(blockId);
                const videoId = getValueByPath(oldBlock, videoIdPath);

                await dispatch('removeActivityAttachmentByPath', {
                    blockId,
                    pathAttach: videoIdPath,
                });

                dispatch('videoBlockShowRestoreVideo', {
                    blockId,
                    videoId,
                    oldBlock,
                });
            } catch (e) {
                showError(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @returns {Promise<void>}
         */
        async videoBlockDeleteCoverFile({ dispatch }, payload) {
            const { blockId } = payload;

            await dispatch('removeActivityAttachmentByPath', {
                blockId,
                pathAttach: '/data/content/coverFile/id',
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @returns {Promise<void>}
         */
        async videoBlockDeleteSubtitlesFile({ dispatch }, payload) {
            const { blockId } = payload;

            await dispatch('removeActivityAttachmentByPath', {
                blockId,
                pathAttach: '/data/content/subtitlesFile/id',
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.audioId
         * @param {string|number} payload.blockData
         * @returns {Promise<*>}
         */
        videoBlockShowRestoreVideo({ dispatch }, payload) {
            const { blockId, videoId, oldBlock } = payload;

            createSnackbar({
                type: 'timer',
                message: 'Видео удалено',
                actionButton: {
                    iconName: 'undo',
                    text: 'Отмена',
                    on: {
                        click: () => {
                            dispatch('videoBlockRestoreVideo', {
                                blockId,
                                videoId,
                                blockData: oldBlock,
                            });
                        },
                    },
                },
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string|number} payload.blockId
         * @param {string|number} payload.audioId
         * @param {string|number} payload.blockData
         * @returns {Promise<*>}
         */
        async videoBlockRestoreVideo({ dispatch }, payload) {
            const { blockId, videoId, blockData } = payload;

            try {
                await dispatch('restoreActivityAttach', {
                    blockId,
                    attachId: videoId,
                });
                await dispatch('changeBlock', blockData);
            } catch (e) {
                showError(e);
            }
        },

        ...activityQuestionActions,
    },
};
