<template>
    <SbsModal
        v-model="isSupportModalVisible"
        modalBodyClass="SupportModal"
        :width="550">
        <div class="SupportModal__Content">
            <h2 class="SupportModal__Heading">
                Обращение<br>
                в&nbsp;службу поддержки
            </h2>
            <SbsCombobox
                v-bind="comboboxData"
                v-model:opened="isShowSupportThemesDropdown"
                :modelValue="supportData.joinedSupportThemes"
                :items="supportServiceThemes"
                @update:modelValue="updateJoinedSupportThemes">
                <template #list="{ items: selectItems }">
                    <SbsSelectListItem
                        v-for="selectItem in selectItems"
                        :key="selectItem.value"
                        class="SupportModal__ContentThemeItem"
                        labelClass="SupportModal__ContentThemeItemLabel"
                        :label="selectItem.label"
                        :modelValue="selectItem.value"
                        hoverBackground="lightblue"
                        hideControl>
                        <template #default="{ selected }">
                            <div class="SupportModal__ContentThemeItemContent">
                                <div>{{ selectItem.label }}</div>
                                <InlineSvg
                                    v-if="selected"
                                    :src="require('@/assets/svg/icon-accept.svg')" />
                            </div>
                        </template>
                    </SbsSelectListItem>
                </template>
            </SbsCombobox>
            <SbsInput
                v-model="supportData.link"
                class="SupportModal__Link"
                label="Ссылка на страницу — необязательно"
                colorTheme="primary"
                :validationRules="{
                    isUrl: value => !value || validateURL(value),
                }"
                errorMessage="Неправильный формат ссылки. Пример правильного формата: https://www.site.ru/" />
            <SbsTextarea
                v-model="supportData.message"
                class="SupportModal__Textarea"
                textareaFieldClass="SupportModal__TextareaField"
                fullWidth
                :rows="7"
                colorTheme="primary"
                label="Опишите суть"
                :minLength="10" />

            <div class="SupportModal__Actions">
                <div
                    class="SupportModal__UploaderWrapper"
                    :class="{ 'SupportModal__UploaderWrapper--Loading': isLoading }">
                    <FileUploader
                        v-model="supportModalFiles"
                        class="SupportModal__FileUploader"
                        :acceptFileExtensions="acceptFileExtensions"
                        :maxFileSize="maxFileSize"
                        @dragenter="setShowSupportModalUploaderActive(true)"
                        @dragleave="setShowSupportModalUploaderActive(false)"
                        @change="uploaderChangeHandler">
                        <template #upload>
                            <div class="SupportModal__FileUploaderContent">
                                <div
                                    class="SupportModal__FileUploaderContentIconWrapper"
                                    :class="{ 'SupportModal__FileUploaderContentIconWrapper--Active': isSupportModalUploaderActive }">
                                    <InlineSvg :src="require('@/assets/svg/icons8-install-in.svg')" />
                                </div>
                                <div>
                                    <div>
                                        Нажмите или перетащите,<br>
                                        картинку или видео сюда
                                    </div>
                                    <div class="SupportModal__FileUploaderContentInfo">
                                        <span>Размер до {{ maxFileSize }} Мб</span>
                                        <span>•</span>
                                        <SupportExtensionsPopup
                                            v-model="isShowDetailsPopup"
                                            :maxFileSize="maxFileSize">
                                            <SbsLink
                                                class="SupportModal__FileUploaderContentInfoLink"
                                                colorTheme="gray-deep"
                                                size="small"
                                                hoverBorder="primary"
                                                @click.prevent="toggleShowDetailsPopup">
                                                Подробнее
                                            </SbsLink>
                                        </SupportExtensionsPopup>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </FileUploader>
                </div>

                <div class="SupportModal__Submit">
                    <SbsTooltip
                        tooltipClass="SupportModal__SubmitToolip"
                        :maxWidth="231"
                        :disabled="!isSupportSendDisabled">
                        <template #content>
                            <div class="SupportModal__SubmitToolipText">
                                <template v-if="isResendTimerVisible">
                                    Новое обращение можно
                                    <b>
                                        отправить через
                                        {{ timerSecondsMessage }}
                                    </b>
                                </template>
                                <template v-else>
                                    Опишите суть,<br>
                                    чтобы отправить обращение
                                </template>
                            </div>
                        </template>
                        <div>
                            <SbsButton
                                class="SupportModal__SubmitBtn"
                                v-bind="supportFormButtonData"
                                @click="sendSupportForm">
                                Отправить
                            </SbsButton>
                        </div>
                    </SbsTooltip>
                </div>
            </div>
        </div>
    </SbsModal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import isURL from 'validator/lib/isURL.js';
import { sendFeedback, getSupportFeedbackTypes } from '@/api/supportApi.js';
import useSnackbar from '@/hooks/snackbars.js';
import { FileTypes } from '@/utils/fileTypes.js';
import { userGetterTypes } from '@/store/modules/user.js';
import FileUploader from '@/components/UI/FileUploader.vue';
import SupportExtensionsPopup from './SupportExtensionsPopup.vue';

const { mapGetters } = createNamespacedHelpers('user');

const COMPLETED_PROGRESS = 100;
const MIN_SUPPORT_TEXT_LENGTH = 10;
const SECONDS_IN_MINUTE = 60;

export default {
    name: 'SupportModal',
    components: {
        FileUploader,
        InlineSvg,
        SupportExtensionsPopup,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            maxFileSize: 15,
            isLoading: false,
            timeForResend: 0,
            supportServiceThemes: [],
            supportData: {
                message: '',
                file: null,
                joinedSupportThemes: [],
                link: '',
            },
            supportModalFiles: [],
            isSupportModalUploaderActive: false,
            timerId: null,

            isShowSupportThemesDropdown: false,
            isShowDetailsPopup: false,
            comboboxData: {
                class: 'SupportModal__ContentTheme',
                label: 'Тема обращения',
                colorTheme: 'primary',
                popupProps: {
                    offset: 15,
                },
                fullWidth: true,
                backgroundTheme: 'white',
            },
        };
    },
    computed: {
        ...mapGetters({ user: userGetterTypes.GET_USER }),
        acceptFileExtensions() {
            const { picture, video } = FileTypes;

            return [...picture.EXTENSIONS, ...video.EXTENSIONS].map(ext => `.${ext}`);
        },
        isSupportModalVisible: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        timerSecondsMessage() {
            const secondsText = this.$utils.pluralize(this.timeForResend, ['секунду', 'секунды', 'секунд']);

            return `${this.timeForResend} ${secondsText}`;
        },
        isResendTimerVisible() {
            return Boolean(this.timeForResend);
        },
        isSupportSendDisabled() {
            return (
                this.isResendTimerVisible ||
                this.supportData.message.length < MIN_SUPPORT_TEXT_LENGTH ||
                !this.isValidLink || !this.supportData.joinedSupportThemes.length
            );
        },
        supportFormButtonData() {
            return {
                backgroundTheme: 'primary',
                bordered: false,
                colorTheme: 'white',
                hoverShadow: 'primary',
                disabled: this.isSupportSendDisabled,
                loading: this.isLoading,
                size: 'large',
                static: true,
            };
        },
        isValidLink() {
            if (!this.supportData.link.length) return true;

            return this.validateURL(this.supportData.link);
        },
    },
    methods: {
        uploaderChangeHandler(files) {
            this.supportData.file = files[0];
        },
        setProgress(e, file) {
            const totalLength = e.lengthComputable
                ? e.total
                : e.target.getResponseHeader('content-length') || e.target.getResponseHeader('x-decompressed-content-length');

            if (totalLength !== null) {
                let progress = Math.round((e.loaded * COMPLETED_PROGRESS) / totalLength);

                if (progress > COMPLETED_PROGRESS) {
                    progress = COMPLETED_PROGRESS;
                }

                this.supportModalFiles = [
                    {
                        ...file,
                        progress,
                    },
                ];
            }
        },
        // eslint-disable-next-line max-lines-per-function
        async sendSupportForm() {
            this.isLoading = true;

            try {
                const { headers } = await sendFeedback(
                    {
                        ...this.supportData,
                        file: this.supportData.file?.originalFile || null,
                        support_type_id: this.supportData.joinedSupportThemes[0],
                    },
                    e => {
                        if (!this.supportData.file) return;

                        this.setProgress(e, this.supportData.file);
                    },
                );

                this.createResendTimer(headers);
                this.hideSupportModal();
                this.createSnackbar({
                    message: `Ответ придет Вам на почту: ${this.user.email}`,
                    size: 'large',
                    title: 'Обращение отправлено',
                    type: 'success',
                });
            } catch (e) {
                console.error(e);

                const { status, headers } = e.response;

                if (status === 429) {
                    this.createResendTimer(headers);

                    this.createSnackbar({
                        title: 'Вы уже недавно отправляли обращение в службу поддержки',
                        message: `Новое можно отправить через ${this.timerSecondsMessage}`,
                        type: 'error',
                        size: 'large',
                    });
                } else {
                    this.createSnackbar({
                        message: 'Ошибка. Попробуйте еще раз',
                        type: 'error',
                    });
                }
            } finally {
                this.isLoading = false;
            }
        },
        hideSupportModal() {
            this.supportData.message = '';
            this.supportModalFiles = [];
            this.isSupportModalVisible = false;
        },
        createResendTimer(headers) {
            this.destroyResendTimer();
            this.setResendTime(headers);
            this.timerId = setInterval(() => {
                this.timeForResend = Math.max(this.timeForResend - 1, 0);

                if (!this.timeForResend) {
                    this.destroyResendTimer();
                }
            }, 1000);
        },
        destroyResendTimer() {
            clearInterval(this.timerId);
        },
        setResendTime(headers) {
            const resetTime = parseInt(headers['retry-after'], 10) || SECONDS_IN_MINUTE;

            this.timeForResend = resetTime;
        },
        updateJoinedSupportThemes(arrayValue) {
            this.isShowSupportThemesDropdown = false;

            if (arrayValue.length < 1) return;

            this.supportData.joinedSupportThemes = [arrayValue[1]];
        },
        toggleShowDetailsPopup() {
            this.isShowDetailsPopup = !this.isShowDetailsPopup;
        },
        setShowSupportModalUploaderActive(isShow) {
            this.isSupportModalUploaderActive = isShow;
        },
        validateURL(value) {
            return isURL(value.trim());
        },
        parseSupportType(type) {
            return ({
                label: type.name,
                value: String(type.id),
                isDefault: type.is_default,
            });
        },
        async getSupportTypes() {
            const { data } = await getSupportFeedbackTypes();

            const comboboxItems = data.data.map(el => this.parseSupportType(el));
            const defaultItems = comboboxItems.filter(el => el.isDefault).map(el => el.value);

            this.supportData.joinedSupportThemes = defaultItems;
            this.supportServiceThemes = comboboxItems;
        },
    },
    setup() {
        const { createSnackbar } = useSnackbar();

        return {
            createSnackbar,
        };
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        supportModalFiles(newVal) {
            if (!newVal?.length) {
                this.supportData.file = null;
            }
        },
        modelValue(newVal) {
            if (!newVal) return;

            this.supportData.link = window.location.href;
        },
    },
    async mounted() {
        await this.getSupportTypes();
    },
};
</script>

<style lang="scss">
.SupportModal {
    padding: 0;
}

.SupportModal__Content {
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
}

.SupportModal__Heading {
    margin-bottom: 30px;
    text-align: center;

    @include typo-h2-bold;
}

.SupportModal__ContentTheme {
    margin-bottom: 10px;
}

.SupportModal__ContentThemeItemLabel {
    height: 34px;
    padding: 7px 20px;
    border-radius: 0;
}

.SupportModal__ContentThemeItemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;

    @include typo-additional-book;
}

.SupportModal__Link {
    margin-bottom: 10px;
}

.SupportModal__Textarea {
    margin-bottom: 25px;
    border-radius: 15px;
}

.SupportModal__TextareaField {
    @include typo-primary-book;
}

.SupportModal__Actions {
    display: flex;
    justify-content: space-between;
}

.SupportModal__UploaderWrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 25px;

    .sbs-file-previewer__file-info {
        opacity: 1;
    }

    &--Loading {
        pointer-events: none;

        .sbs-file-previewer__file-info {
            opacity: 0.4;
        }
    }
}

.SupportModal__FileUploader {
    flex-grow: 1;
}

.SupportModal__FileUploaderContent {
    display: flex;
    gap: 14px;

    @include typo-subsecondary-book;
}

.SupportModal__FileUploaderContentIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    background: $gray-ultra-light;
    border: 1px dashed $gray-light;
    border-radius: 10px;

    &--Active {
        border-color: $primary;

        svg path {
            fill: $primary;
        }
    }
}

.SupportModal__FileUploaderContentInfo {
    position: relative;
    z-index: 11;
    display: flex;
    gap: 5px;
    align-items: center;
    color: $gray-deep;
}

.SupportModal__Submit {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
}

.SupportModal__SubmitToolip {
    text-align: left;
}

.SupportModal__SubmitToolipText {
    @include typo-additional-book;
}

.SupportModal__SubmitBtn {
    @include typo-primary-demi;
}
</style>
