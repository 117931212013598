<template>
    <SbsPopup
        v-model="isShowPopup"
        :width="400"
        popupClass="SupportExtensionsPopup">
        <slot></slot>
        <template #content>
            <div class="SupportExtensionsPopup__Title">
                Требования к загрузке
            </div>
            <div class="SupportExtensionsPopup__Subtitle">
                Максимальный размер загружаемого файла
                не должен превышать {{ maxFileSize }} Мб. Загрузить можно только один файл
            </div>
            <div class="SupportExtensionsPopup__ExtensionsTitle">
                Разрешение файлов:
            </div>
            <div class="SupportExtensionsPopup__Extensions">
                <div
                    v-for="extension in extensionsItems"
                    :key="extension.title"
                    class="SupportExtensionsPopup__Extension"
                    :class="extension.class">
                    <div class="SupportExtensionsPopup__ExtensionIconWrapper">
                        <InlineSvg :src="extension.icon" />
                    </div>
                    <div class="SupportExtensionsPopup__ExtensionContent">
                        <div class="SupportExtensionsPopup__ExtensionContentTitle">
                            {{ extension.title }}
                        </div>
                        <div class="SupportExtensionsPopup__ExtensionContentList">
                            {{ extension.extensions }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </SbsPopup>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { FileTypes } from '@/utils/fileTypes.js';
import pictureIcon from '@/assets/svg/icon8-image.svg';
import videoIcon from '@/assets/svg/icon8-video.svg';

const extensionsItems = [
    {
        title: 'Картинка',
        icon: pictureIcon,
        extensions: FileTypes.picture.EXTENSIONS.join(', '),
        class: 'SupportExtensionsPopup__Extension--Picture',
    },
    {
        title: 'Видео',
        icon: videoIcon,
        extensions: FileTypes.video.EXTENSIONS.join(', '),
        class: 'SupportExtensionsPopup__Extension--Video',
    },
];

export default {
    name: 'SupportExtensionsPopup',
    components: {
        InlineSvg,
    },
    emits: ['update:modelValue'],
    data: () => ({
        extensionsItems,
    }),
    computed: {
        isShowPopup: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        maxFileSize: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss">
.SupportExtensionsPopup {
    padding: 20px 25px;
}

.SupportExtensionsPopup__Title {
    margin-bottom: 20px;

    @include typo-primary-demi;
}

.SupportExtensionsPopup__Subtitle {
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: $gray-deep;
    border-bottom: 1px solid #ebedf1;

    @include typo-additional-book;
}

.SupportExtensionsPopup__ExtensionsTitle {
    margin-bottom: 15px;

    @include typo-additional-demi;
}

.SupportExtensionsPopup__Extensions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.SupportExtensionsPopup__Extension {
    display: flex;
    gap: 10px;

    &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px dashed #ebedf1;
    }

    &--Picture {
        .SupportExtensionsPopup__ExtensionIconWrapper {
            background: linear-gradient(135deg, #e0f9ed 0%, #ddf4f2 99.29%);
        }

        .SupportExtensionsPopup__ExtensionContentTitle {
            color: #23b883;
        }
    }

    &--Video {
        .SupportExtensionsPopup__ExtensionIconWrapper {
            background: linear-gradient(135deg, #fef9df 0%, #feecdb 95.95%);
        }

        .SupportExtensionsPopup__ExtensionContentTitle {
            color: transparent;
            background: linear-gradient(135deg, #ff9426 0%, #f21365 91.96%);
            -webkit-background-clip: text;
            background-clip: text;
        }
    }
}

.SupportExtensionsPopup__ExtensionContent {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.SupportExtensionsPopup__ExtensionIconWrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 15px;
}

.SupportExtensionsPopup__ExtensionContentTitle {
    @include typo-additional-demi;
}

.SupportExtensionsPopup__ExtensionContentList {
    color: $gray-deep;

    @include typo-subsecondary-book;
}
</style>
